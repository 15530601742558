import React, {useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {changePassword} from "../../services/userService";
import {showServerError} from "../../utils/errorUtils";
import Form from "../Form/Form";
import {FormButton, FormInput} from "pd-shared";

function PasswordSetting() {
    const [success, setSuccess] = useState(false);
    const {t} = useTranslation("settings");

    const schema = useMemo(() => yup.object({
        newPassword: yup.string().min(5).required(),
        password: yup.string().min(5).required(),
        confirmNewPassword: yup.string().oneOf([
            yup.ref("newPassword")
        ], t("passwordsDiffer")).required()
    }), [t]);

    const handleSubmit = async values => {
        const creds = {...values};
        delete creds.confirmNewPassword;

        try {
            await changePassword(creds);
            setSuccess(true);
        } catch (err) {
            showServerError(err);
        }
    }

    return <div>
        {success && <div className="alert alert-success">{t("passwordChanged")}</div>}
        <Form className="settingsPage__form" name="password" schema={schema} onSubmit={handleSubmit}>
            <FormInput path="newPassword" label={t("newPassword")} type="password" autoFocus/>
            <FormInput path="confirmNewPassword" label={t("confirmPassword")} type="password"/>
            <FormInput path="password" label={t("currentPassword")} type="password"/>
            <FormButton className="btn btn-outline-primary">{t("apply")}</FormButton>
        </Form>
    </div>
}

export default PasswordSetting;
