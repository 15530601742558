import React, {useCallback, useEffect, useImperativeHandle, useState} from "react";
import _ from "lodash";
import {FormContext} from "pd-shared";
import {produce} from "immer";

function Form({ name, schema, onSubmit, children, onValueChange, ...rest }, ref) {
    const [values, setValues] = useState({})
    const [errors, setErrors] = useState({})
    const [isSubmitting, setSubmitting] = useState(false)

    const resetValues = useCallback((values = schema ? schema.getDefault() : {}) => {
        setValues(values)
        setErrors({})
        setSubmitting(false)
    }, [schema])

    useEffect(resetValues, [resetValues])

    useEffect(() => {
        onValueChange?.(values);
    }, [values, onValueChange]);

    const getValues = useCallback(async () => {
        if (!schema) return values

        try {
            await schema.validate(values, {abortEarly: false});
            return values;
        } catch (err) {
            setErrors(_.zipObjectDeep(_.map(err.inner, "path"), _.map(err.inner, "message")))
            return null;
        }
    }, [schema, values])

    useImperativeHandle(ref, () =>
        ({ resetValues, getValues }), [resetValues, getValues])

    const setFieldValue = useCallback(async (path, value) => {
        const updatedValues = produce(values, draft => {
            _.set(draft, path, value)
        })
        setValues(updatedValues)

        if (!schema) return

        // Don't put validation in effect, because we don't want it to run because of resetValues
        let error = null
        try {
            await schema.validateAt(path, updatedValues)
        } catch (err) {
            error = err.message
        }

        const updatedErrors = produce(errors, draft => {
            if (error == null)
                _.unset(draft, path)
            else
                _.set(draft, path, error)
        })

        setErrors(updatedErrors)
    }, [errors, schema, values])

    const handleSubmit = useCallback(async e => {
        e.preventDefault();
        setSubmitting(true);

        const values = await getValues()
        if (values)
            await onSubmit(values);

        setSubmitting(false);
    }, [onSubmit, getValues])

    return <form {...rest} onSubmit={handleSubmit} noValidate>
        <FormContext.Provider value={{ errors, values, isSubmitting, setFieldValue, name }}>
            {children}
        </FormContext.Provider>
    </form>
}

export default React.forwardRef(Form)
