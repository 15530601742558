import axios from "axios";
import i18n from "i18next";
import ServerError from "../models/ServerError";
import {getAuthToken, reAuthenticate} from "./authService";

const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

//Request headers
const authTokenHeader = "x-auth-token";
const apiVersionHeader = "x-api-version";

export const withAuthTokenConfig = token => ({
    headers: {
        [authTokenHeader]: token
    }
})

//Response headers
const errorIdHeader = "x-error-id";

const commonHeaders = http.defaults.headers.common;
commonHeaders[apiVersionHeader] = "1";

http.interceptors.request.use(config => {
    // Using ??= in order for withAuthTokenConfig to work
    config.headers[authTokenHeader] ??= getAuthToken();
    return config;
})

http.interceptors.response.use(
    response => response.data,
    error => {
        if (error.response) {
            //Server responded
            const { data, status, headers } = error.response;

            //Translate error message
            const id = headers[errorIdHeader];
            const message = i18n.t(`serverErrors.${id}`, data);
            const serverError = new ServerError(message, status, id);

            //Invalid request
            switch (status) {
                case 401: //Unauthorized
                    reAuthenticate(message)
                    break;
                case 402: //Payment required
                    window.location.replace("/settings/subscription");
                    break;
                default:
                    break;
            }

            return Promise.reject(serverError);
        } else if (error.request) {
            //Server didn't respond
            return Promise.reject(new ServerError(i18n.t("noResponseError")));
        }

        //Error while creating request
        return Promise.reject(error);
    }
)

export default http;
