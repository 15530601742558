import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {Link} from "react-router-dom";
import {register} from "../../services/userService";
import {showServerError} from "../../utils/errorUtils";
import FormPage from "../FormPage/FormPage";
import Form from "../Form/Form";
import {FormButton, FormInput, Modal, useModal} from "pd-shared";

const emailSentModalId = "emailSentModal"

function Register() {
    const {t} = useTranslation("login");

    const schema = useMemo(() => yup.object({
        email: yup.string().email().required(),
        password: yup.string().min(5).required(),
        confirmPassword: yup.string().oneOf([
            yup.ref("password")
        ], t("passwordsDiffer")).required()
    }), [t]);

    const emailSentModal = useModal(emailSentModalId)

    const handleSubmit = async values => {
        const creds = {...values};
        delete creds.confirmPassword;

        try {
            await register(creds);
            emailSentModal.show()
        } catch(err) {
            showServerError(err);
        }
    }

    return <FormPage>
        <Modal title={t("register")} id={emailSentModalId} submitText="">{t("emailSent")}</Modal>

        <Form className="formPage__form" name="register" schema={schema} onSubmit={handleSubmit}>
            <h1 className="formPage__heading">{t("register")}</h1>
            <FormInput path="email" label={t("email")} type="email"/>
            <FormInput path="password" label={t("password")} type="password"/>
            <FormInput path="confirmPassword" label={t("confirmPassword")} type="password"/>
            <div className="form-group">
                <FormButton className="btn btn-outline-primary w-100">{t("register")}</FormButton>
            </div>
            <Link className="btn btn-link" to='/login'>{t("haveAccount")}</Link>
        </Form>
    </FormPage>
}

export default Register;
