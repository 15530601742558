import "./PetList.scss";
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {getPetList} from "../../services/petService";
import {useTranslation} from "react-i18next";
import {age} from "../../utils/formatUtils";
import _ from "lodash";
import getSearchFields from "./petListSearchFields";
import PlaceholderPicture from "./PlaceholderPicture";
import {useNavigate, useParams} from "react-router-dom";
import Form from "../Form/Form";
import {createSearchParams} from "react-router-dom";
import {searchUtils} from "pd-shared";
import Navbar from "../Navbar/Navbar";

function PetList() {
    const {t} = useTranslation("pet");

    const [list, setList] = useState();
    const [error, setError] = useState();
    const [filter, setFilter] = useState({});

    const pets = useMemo(() => {
        if (!list) return [];
        return _.filter(list.pets, p => searchUtils.filterItem(p, filter));
    }, [list, filter]);

    const searchFields = useMemo(() => getSearchFields(t), [t])

    const {categoryId} = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        getPetList(categoryId)
            .then(list => setList(list))
            .catch(err => setError(err))
    }, [categoryId]);

    const openPet = useCallback(id =>
        navigate(`/pet/${id}?${createSearchParams({ from: categoryId })}`),
        [navigate, categoryId]);

    const handleSearchChange = useCallback(values => {
       setFilter(searchFields.getFilter(values));
    }, [searchFields])

    function renderGridItem(pet) {
        const pictureUrl = pet.thumbnail_url

        return <div className="card"
                    key={pet.id}
                    onClick={() => openPet(pet.id)}
        >
            {pictureUrl ? <img src={pictureUrl}
                 className="card-img-top"
                 alt={t("picture")}
            /> : <PlaceholderPicture/>}
            <div className="card-body">
                <p className="card-text">
                    {_.compact([
                        t("sexes")[pet.sex_id],
                        t("sizes")[pet.size_id],
                        age(pet.birth_date)
                    ]).join(", ")}
                    <br/>
                    {pet.location}
                </p>
            </div>
        </div>
    }

    function renderContent() {
        if (error)
            return <div className="text-center">{error.message}</div>

        if (!list)
            return null;

        return <>
            <h1>{list?.name}</h1>
            <Form name="petList" onValueChange={handleSearchChange}>
                {searchFields.components}
            </Form>
            <div id="cards">
                {!pets.length && <div className="text-secondary">{t("noItems")}</div>}
                {_.map(pets, renderGridItem)}
            </div>
        </>
    }

    return <div className="page pet-list">
        <Navbar></Navbar>
        <div className="page__container container py-3">
            {renderContent()}
        </div>
    </div>
}

export default PetList
