import { subMonths } from "date-fns";
import {serverDate} from "pd-shared/formatUtils";
import {SearchComponents, SearchFields, searchModes} from "pd-shared";

function dateRange(minMonths, maxMonths) {
    const range = {};

    if (minMonths)
        range.end = serverDate(subMonths(Date.now(), minMonths))

    if (maxMonths)
        range.start = serverDate(subMonths(Date.now(), maxMonths))

    return range;
}

// _.toPairs converts the index to a string, which we don't want
const arrayToPairs = array =>
    array.map((title, index) => [index, title]);

export default function getSearchFields(t) {
    return new SearchFields({
        sex_id: {
            label: t("sex"),
            component: new SearchComponents.UnlabeledDropdownList(arrayToPairs(t("sexes")))
        },
        size_id: {
            label: t("size"),
            component: new SearchComponents.UnlabeledDropdownList(arrayToPairs(t("sizes")))
        },
        birth_date: {
            label: t("age"),
            component: new SearchComponents.UnlabeledDropdownList([
                [dateRange(null, 6), t("under6Months")],
                [dateRange(6, 12), t("6MonthsToAYear")],
                [dateRange(12, 24), t("1To2Years")],
                [dateRange(24, 60), t("2To5Years")],
                [dateRange(60, null), t("above5Years")]
            ]),
            mode: searchModes.Range
        },
        sterilized: {
            label: t("onlySterilized"),
            component: new SearchComponents.CheckboxOnlyTrue(),
            mode: searchModes.BooleanMatch
        }
    })
}
