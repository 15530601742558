import React from 'react';
import * as yup from "yup";
import withListSetting from "../../hoc/withListSetting";
import * as service from "../../services/vetService";
import {vetsNs} from "../../stores/settingsStore";
import {FormInput} from "pd-shared";

const schema = yup.object({
    name: yup.string().required(),
    email: yup.string(),
    phone: yup.string(),
    city: yup.string(),
    address: yup.string()
});

function getColumns(t) {
    return [
        {
            title: t("name"),
            path: "name",
            defaultWidth: 30
        },
        {
            title: t("email"),
            path: "email",
            defaultWidth: 25
        },
        {
            title: t("phone"),
            path: "phone",
            defaultWidth: 20
        },
        {
            title: t("city"),
            path: "city",
            defaultWidth: 20
        },
        {
            title: t("address"),
            path: "address",
            defaultWidth: 20
        }
    ];
}

function VetsSetting({t}) {
    return <>
        <FormInput path="name" label={t("name")}/>
        <FormInput path="email" label={t("email")} type="email"/>
        <FormInput path="phone" label={t("phone")} type="tel"/>
        <FormInput path="city" label={t("city")}/>
        <FormInput path="address" label={t("address")}/>
    </>
}

export default withListSetting(
    VetsSetting,
    vetsNs,
    schema,
    getColumns,
    service.getVets,
    service.createVet,
    service.editVet,
    service.deleteVet
)
