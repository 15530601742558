import React from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import * as yup from "yup";
import {forgotPassword} from "../../services/userService";
import {showServerError} from "../../utils/errorUtils";
import FormPage from "../FormPage/FormPage";
import Form from "../Form/Form";
import {FormButton, FormInput, Modal, useModal} from "pd-shared";

const emailSentModalId = "emailSentModal"

const schema = yup.object({
    email: yup.string().email().required()
});

function Forgot() {
    const {t} = useTranslation("login");

    const emailSentModal = useModal(emailSentModalId)

    const handleSubmit = async values => {
        try {
            await forgotPassword(values);
            emailSentModal.show();
        } catch(err) {
            showServerError(err);
        }
    }

    return <FormPage>
        <Modal title={t("reset")} id={emailSentModalId}>{t("emailSent")}</Modal>
        <Form className="formPage__form" name="forgot" schema={schema} onSubmit={handleSubmit}>
            <h1 className="formPage__heading">{t("forgot")}</h1>
            <FormInput path="email" label={t("email")} type="email"/>
            <div className="form-group">
                <FormButton className="btn btn-outline-primary w-100">{t("reset")}</FormButton>
            </div>
            <Link className="btn btn-link" to='/login'>{t("rememberedIt")}</Link>
        </Form>
    </FormPage>
}

export default Forgot;
