import React, {useEffect, useState, useMemo, useCallback, useRef} from 'react';
import {getTableUtils, Table, dlMapUtils, setUtils} from "react-select-table";
import {useTranslation} from "react-i18next";
import $ from "jquery";
import {showServerError} from "../utils/errorUtils";
import Form from "../components/Form/Form";
import useCollapse from "../hooks/useCollapse";
import {idSelector} from "../utils/elementUtils";
import {FormButton, useJQueryEvent} from "pd-shared";

export default function withListSetting (
    FormControls,
    namespace, schema, getColumns,
    getItems, createItem, editItem, deleteItem
) {
    return function WithList() {
        const {t} = useTranslation("settings");
        const formRef = useRef()

        const [editingId, setEditingId] = useState(null)

        const { hooks, selectors } = getTableUtils(namespace)

        const getState = hooks.useGetState()
        const noneSelected = hooks.useSelector(s => setUtils.isEmpty(s.selected))

        const actions = hooks.useActions();

        const columns = useMemo(() => getColumns(t), [t])

        const accordionId = namespace + "Accordion"
        const tableId = namespace + "Table"
        const formId = namespace + "Form"

        const tableCollapse = useCollapse(tableId)
        const formCollapse = useCollapse(formId)


        const reloadItems = useCallback(() => {
            getItems()
                .then(items => actions.setItems(items))
                .catch(err => showServerError(err, t("getError")))
        }, [actions, t])

        useEffect(reloadItems, [reloadItems]);

        const focusTable = useCallback(() => {
            $(tableCollapse.selector).find(".rst-container").focus();
        }, [tableCollapse])
        useJQueryEvent(tableCollapse.selector, "shown.bs.collapse", focusTable)

        const focusFirstInput = useCallback(() => {
            $(formCollapse.selector).find(".settingsPage__input-grid > :first-child input").focus();
        }, [formCollapse])
        useJQueryEvent(formCollapse.selector, "shown.bs.collapse", focusFirstInput)

        const handleCreate = useCallback(() => {
            setEditingId(null)
            formRef.current.resetValues()
            formCollapse.show()
        }, [formCollapse, formRef])

        const handleEdit = useCallback(() => {
            const state = getState()
            const selected = selectors.getSelectionArg(state)

            setEditingId(selected)
            formRef.current.resetValues(dlMapUtils.getItem(state.items, selected))
            formCollapse.show()
        }, [formCollapse, getState, formRef, selectors])

        const handleSubmit = useCallback(async values => {
            const body = schema.cast(values, {stripUnknown: true});
            try {
                let id = editingId;
                if (editingId) {
                    await editItem(editingId, body);
                } else {
                    id = await createItem(body);
                }

                actions.addItems({ ...body, id });
            } catch (err) {
                reloadItems();
                showServerError(err, t(editingId ? "editError" : "createError"));
            }

            if (editingId)
                tableCollapse.show()
            else {
                formRef.current.resetValues()
                focusFirstInput()
            }
        }, [editingId, tableCollapse, actions, reloadItems, t, focusFirstInput])

        const handleDelete = useCallback(async () => {
            const selected = selectors.getSelectionArg(getState())
            if (!selected) return

            try {
                await deleteItem(selected);
                actions.deleteItems(selected);
            } catch(err) {
                reloadItems();
                showServerError(err, t("deleteError"));
            }

            focusTable()
        }, [actions, focusTable, getState, reloadItems, selectors, t])

        const extraProps = { t };

        const handleOnKeyDown = useCallback(async e => {
            if (e.keyCode !== 46) return // Delete
            await handleDelete()
        }, [handleDelete])

        return <>
            <div className="accordion" id={accordionId}>
                <div id={formId} className="collapse" data-parent={idSelector(accordionId)}>
                    <Form ref={formRef} name={namespace} onSubmit={handleSubmit}>
                        <div className="settingsPage__input-grid">
                            <FormControls {...extraProps}/>
                        </div>
                        <div className="settingsPage__button-strip">
                            <FormButton className="btn btn-outline-primary">
                                {t(editingId ? "apply" : "add")}
                            </FormButton>
                            <button type="button"
                                    className="btn btn-outline-secondary"
                                    {...tableCollapse.toggleProps}
                            >{t("close")}</button>
                        </div>
                    </Form>
                </div>
                <div id={tableId} className="collapse show" data-parent={idSelector(accordionId)}>
                    <div className="settingsPage__button-strip">
                        <button className="btn btn-outline-primary"
                                onClick={handleCreate}
                        >{t("create")}</button>

                        <button className="btn btn-outline-primary"
                                onClick={handleEdit}
                                disabled={noneSelected}
                        >{t("edit")}</button>

                        <button className="btn btn-outline-danger"
                                onClick={handleDelete}
                                disabled={noneSelected}
                        >{t("delete")}</button>
                    </div>

                    <Table namespace={namespace}
                           className='settingsPage__table'
                           columns={columns}
                           emptyPlaceholder={t("noItems")}
                           onItemsOpen={handleEdit}
                           onKeyDown={handleOnKeyDown}
                    />
                </div>
            </div>
        </>
    }
};
