import http from "./httpService";

const endpoint = "/vets";

export const getVets = () =>
    http.get(endpoint);

export const createVet = vet =>
    http.post(endpoint, vet)

export const editVet = (id, vet) =>
    http.put(`${endpoint}/${id}`, vet);

export const deleteVet = id =>
    http.delete(`${endpoint}/${id}`);