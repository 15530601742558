import React, {useState} from 'react';
import PropTypes from 'prop-types';

Switch.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func
};

Switch.defaultProps = {
    onChange: () => {}
}

function Switch({id, label, onChange, ...rest}) {
    const [isChanging, setChanging] = useState(false);

    const handleChange = async e => {
        setChanging(true);
        await onChange(e);
        setChanging(false);
    }

    if (isChanging) return (
        <div className="custom-control custom-spinner">
            <label className="custom-control-label">{label}</label>
        </div>
    )

    return <div className="custom-control custom-switch">
        <input {...rest}
               type="checkbox"
               className="custom-control-input"
               id={id}
               onChange={handleChange}
        />
        <label className="custom-control-label"
               htmlFor={id}
        >{label}</label>
    </div>
}

export default Switch;