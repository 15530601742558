import "./Pet.scss";
import React, {useCallback, useEffect, useState} from 'react';
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {Link, useParams, useSearchParams} from "react-router-dom";
import {getPetInfo} from "../../services/petService";
import PlaceholderPicture from "../PetList/PlaceholderPicture";
import {clientDate} from "pd-shared/formatUtils";
import Navbar from "../Navbar/Navbar";

function Pet() {
    const {t} = useTranslation("pet");

    const [info, setInfo] = useState()
    const [error, setError] = useState()

    const { petId } = useParams()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        getPetInfo(petId).then(setInfo).catch(setError)
    }, [petId])

    const renderField = useCallback((label, value, id = null) =>
        <div className="field" id={id}>
            <b>{label}</b>
            <div>{value || "-"}</div>
        </div>, []);

    //Rendering
    function renderStatus() {
        switch(info.pet.status_id) {
            case 4: //Shelter
                return <>
                    {renderField(t("shelterName"), info.shelter?.name)}
                </>;
            case 5: //Hospitality
                return <>
                    {renderField(t("city"), info.hospitality?.city)}
                </>;
            default:
                return null;
        }
    }

    function renderContent() {
        if (error) return <div className="text-center">{error.message}</div>
        if (!info) return null;

        const { pet } = info;
        const pictureUrl = pet.picture_url
        return <div className="pet-viewer__grid">
            <div className="pet-viewer__picture-container">
                {pictureUrl
                    ? <img className="w-100" src={pictureUrl} alt={t("picture")} />
                    : <PlaceholderPicture id="picture"/>}
            </div>
            {renderField(t("characteristics"), pet.characteristics)}
            {renderField(t("weight"), pet.weight)}
            {renderField(t("name"), pet.name)}
            {renderField(t("birthDate"),
                `${clientDate(pet.birth_date)} ${pet.birth_date_estimated ? t("estimated") : ""}`)}
            {renderField(t("sterilizationDate"), clientDate(pet.sterilization_date))}
            {renderField(t("breed"), pet.breed)}
            {renderField(t("hairType"), pet.hair_type)}
            {renderField(t("sex"), t("sexes")[pet.sex_id])}
            {renderField(t("size"), t("sizes")[pet.size_id])}
            {renderField(t("color"), pet.color)}
            {renderField(t("chip"), pet.chip)}
            {renderField(t("notes"), pet.notes, "notes")}

            <div id="status">
                <h3>{t("status")}: {t("statuses")[pet.status_id]}</h3>
                {renderStatus()}
            </div>

            {/* Examinations */}
            <table className="table">
                <thead>
                <tr>
                    <th width="40%">{t("exam")}</th>
                    <th>{t("date")}</th>
                    <th>{t("result")}</th>
                </tr>
                </thead>
                <tbody>
                {!info.examination?.length && <tr>
                    <td colSpan="3" className="text-secondary text-center">{t("noItems")}</td>
                </tr>}
                {_.map(info.examination, item => <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{clientDate(item.date)}</td>
                    <td>{item.result}</td>
                </tr>)}
                </tbody>
            </table>

            {/* Treatments */}
            <table className="table">
                <thead>
                <tr>
                    <th width="70%">{t("treatment")}</th>
                    <th>{t("date")}</th>
                </tr>
                </thead>
                <tbody>
                {!info.treatments?.length && <tr>
                    <td colSpan="2" className="text-secondary text-center">{t("noItems")}</td>
                </tr>}
                {_.map(info.treatments, item => <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{clientDate(item.date)}</td>
                </tr>)}
                </tbody>
            </table>

            {/* Vaccines */}
            <table className="table">
                <thead>
                <tr>
                    <th width="40%">{t("vaccine")}</th>
                    <th>{t("date")}</th>
                    <th>{t("nextDate")}</th>
                </tr>
                </thead>
                <tbody>
                {!info.vaccines?.length && <tr>
                    <td colSpan="3" className="text-secondary text-center">{t("noItems")}</td>
                </tr>}
                {_.map(info.vaccines, item => <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{clientDate(item.date)}</td>
                    <td>{clientDate(item.next_date)}</td>
                </tr>)}
                </tbody>
            </table>

            {/* Surgeries */}
            <table className="table">
                <thead>
                <tr>
                    <th width="70%">{t("surgery")}</th>
                    <th>{t("date")}</th>
                </tr>
                </thead>
                <tbody>
                {!info.surgeries?.length && <tr>
                    <td colSpan="2" className="text-secondary text-center">{t("noItems")}</td>
                </tr>}
                {_.map(info.surgeries, item => <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{clientDate(item.date)}</td>
                </tr>)}
                </tbody>
            </table>
        </div>
    }

    return <div className="page pet-viewer">
        <Navbar>
            {searchParams.has("from") &&
                <Link to={`/list/${searchParams.get("from")}`}
                      className="pd-navbar__link"
                >{t("returnToList")}</Link>}
        </Navbar>
        <div className="page__container container py-4">
            {renderContent()}
        </div>
    </div>
}

export default Pet;
