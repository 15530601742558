import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {resetPassword} from "../../services/userService";
import {showServerError} from "../../utils/errorUtils";
import FormPage from "../FormPage/FormPage";
import {useNavigate, useParams} from "react-router-dom";
import Form from "../Form/Form";
import {FormButton, FormInput} from "pd-shared";

function Reset() {
    const navigate = useNavigate()
    const { token } = useParams()
    const {t} = useTranslation("login");

    const schema = useMemo(() => yup.object({
        newPassword: yup.string().min(5).required(),
        confirmNewPassword: yup.string().oneOf([
            yup.ref("newPassword")
        ], t("passwordsDiffer")).required()
    }), [t]);

    const handleSubmit = async values => {
        const creds = { ...values, token };
        delete creds.confirmNewPassword;

        try {
            await resetPassword(creds);
            navigate("/login");
        } catch(err) {
            showServerError(err);
        }
    }

    return <FormPage>
        <Form className="formPage__form" name="reset" schema={schema} onSubmit={handleSubmit}>
            <h1 className="formPage__heading">{t("resetPassword")}</h1>
            <FormInput path="newPassword" label={t("newPassword")} type="password"/>
            <FormInput path="confirmNewPassword" label={t("confirmPassword")} type="password"/>
            <FormButton className="btn btn-outline-primary w-100">{t("apply")}</FormButton>
        </Form>
    </FormPage>
}

export default Reset;
