import i18n from "i18next";
import {differenceInDays, differenceInMonths, differenceInYears} from "date-fns";

export const age = dateString => {
    const date = Date.parse(dateString)

    const years = differenceInYears(Date.now(), date)
    if (years > 0)
        return i18n.t("yearsOld", { years });

    const months = differenceInMonths(Date.now(), date)
    if (months > 0)
        return i18n.t("monthsOld", { months });

    const days = differenceInDays(Date.now(), date)
    if (days > 0)
        return i18n.t("daysOld", { days });
}
