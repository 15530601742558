import React from 'react';
import * as yup from "yup";
import * as service from "../../services/locationService";
import withListSetting from "../../hoc/withListSetting";
import {locationsNs} from "../../stores/settingsStore";
import {FormInput} from "pd-shared";

const schema = yup.object({
    name: yup.string().required()
});

function getColumns(t) {
    return [
        {
            title: t("location"),
            path: "name"
        }
    ];
}

function LocationsSetting({t}) {
    return <>
        <FormInput path="name" label={t("location")} />
    </>
}

export default withListSetting(
    LocationsSetting,
    locationsNs,
    schema,
    getColumns,
    service.getLocations,
    service.createLocation,
    service.editLocation,
    service.deleteLocation
)
