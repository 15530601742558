import React from 'react';
import Logo from "../Icons/Logo";
import './Navbar.scss'

function Navbar({ children }) {
    return (
        <nav className="pd-navbar">
            <div className="container pd-navbar__container">
                <a className="pd-navbar__home-link" href={process.env.REACT_APP_WEB_URL}>
                    <Logo className="pd-navbar__logo"/>
                </a>
                {children}
            </div>
        </nav>
    );
}

export default Navbar;
