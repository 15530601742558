import "./App.scss";
import "pd-shared/styles";

import "pd-shared";
import "bootstrap";
import React, {Suspense} from 'react';
import {ToastContainer} from "react-toastify";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import "../../startup/index";

import Login from "../Login/Login";
import Register from "../Register/Register";
import NotFound from "../NotFound/NotFound";
import Settings from "../Settings/Settings";
import Forgot from "../Forgot/Forgot";
import Reset from "../Reset/Reset";
import PetList from "../PetList/PetList";
import Pet from "../Pet/Pet";
import ProtectedRoute from "./ProtectedRoute";
import EmailSetting from "../Settings/EmailSetting";
import LocationsSetting from "../Settings/LocationsSetting";
import VetsSetting from "../Settings/VetsSetting";
import WelcomeSetting from "../Settings/WelcomeSetting";
import SubscriptionSetting from "../Settings/SubscriptionSetting";
import PasswordSetting from "../Settings/PasswordSetting";

function App() {
    return <Suspense fallback={<div className="p-3">Please wait...</div>}>
        <ToastContainer position="top-right"/>
        <BrowserRouter>
            <Routes>
                <Route path="settings" element={<ProtectedRoute><Settings /></ProtectedRoute>}>
                    <Route path="welcome" element={<WelcomeSetting/>} />
                    <Route path="email" element={<EmailSetting/>} />
                    <Route path="locations" element={<LocationsSetting/>} />
                    <Route path="subscription" element={<SubscriptionSetting/>} />
                    <Route path="vets" element={<VetsSetting/>} />
                    <Route path="password" element={<PasswordSetting/>} />
                    <Route index path="*" element={<Navigate to="welcome"/>} />
                </Route>
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="forgot" element={<Forgot />} />
                <Route path="reset/:token" element={<Reset />} />
                <Route path="pet/:petId" element={<Pet />} />
                <Route path="list/:categoryId" element={<PetList />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    </Suspense>
}

export default App;
