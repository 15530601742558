import React from "react";
import Navbar from "../Navbar/Navbar";
import './FormPage.scss'

function FormPage({ children, alert }) {
    return <div className='page formPage'>
        <Navbar>
            <div className="pd-navbar__alert">{alert}</div>
        </Navbar>
        <div className='page__container formPage__container'>
            {children}
        </div>
    </div>
}

FormPage.defaultProps = {
    alertClass: "alert-success"
}

export default FormPage
