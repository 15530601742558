import React, {useEffect, useRef, useState} from 'react';
import dropIn from "braintree-web-drop-in";
import classNames from "classnames";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {
    createSubscription,
    getBraintreeToken,
    getPlans,
    getSubscriptionStatus,
    setAutoRenew,
    setPaymentMethod
} from "../../services/subscriptionService";
import useTooltips from "../../hooks/useTooltips";
import {getAuthEmail} from "../../services/authService";
import {QuestionIcon} from "@primer/octicons-react";
import Switch from "../Controls/Switch";
import Form from "../Form/Form";
import {FormDropdownList, FormInput} from "pd-shared";

function HelpIcon({tooltip}) {
    return <span data-toggle="tooltip"
                 data-placement="bottom"
                 className="help-icon"
                 title={tooltip}
    ><QuestionIcon verticalAlign="top"/></span>
}

function SubscriptionSetting() {
    useTooltips();

    const {t} = useTranslation("settings");
    const [plans, setPlans] = useState();
    const [isSelect, setSelect] = useState(true);
    const [allowRequest, setAllowRequest] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState("yearly");
    const [subStatus, setSubStatus] = useState(null);
    const dropInRef = useRef();
    const dropInContainerRef = useRef();
    const buyerInfoFormRef = useRef();

    const isLoading = subStatus == null;

    useEffect(() => {
        getSubscriptionStatus().then(setSubStatus);
    }, []);

    useEffect(() => {
        getPlans().then(plans => setPlans(_.keyBy(plans, "id")));
    }, []);

    useEffect(() => {
        if (isLoading) return;

        (async function setup() {
            const token = await getBraintreeToken()

            const container = dropInContainerRef.current
            if (!container) return

            const instance = await dropIn.create({
                authorization: token,
                container,
                threeDSecure: true,
                translations: t("braintree", {
                    skipInterpolation: true
                })
            });

            instance.on("paymentMethodRequestable", e => {
                setSelect(e.paymentMethodIsSelected);
                setAllowRequest(true);
            });

            instance.on("noPaymentMethodRequestable", () => {
                setSelect(false);
                setAllowRequest(false);
            });

            setAllowRequest(instance.isPaymentMethodRequestable())

            dropInRef.current = instance
        })();

        return () => {
            const dropIn = dropInRef.current;
            if (!dropIn) return;

            dropIn.teardown();
        }
    }, [t, isLoading]);

    if (isLoading) return t("pleaseWait");
    const isTrial = subStatus.trialDaysRemaining != null;
    const willExpire = subStatus.expirationDate != null;
    const isFrozen = subStatus.daysUntilDelete != null;

    const handleRequestClick = async () => {
        const dropIn = dropInRef.current;

        if (!isSelect)
            //Only verify card when selected
            return await dropIn.requestPaymentMethod();

        const buyerInfo = await buyerInfoFormRef.current.getValues()
        if (!buyerInfo) return //Form validation failed

        const plan = plans[selectedPlan];

        const method = await dropIn.requestPaymentMethod({
            threeDSecure: {
                amount: plan.price,
                email: getAuthEmail(),
                billingAddress: buyerInfo
            }
        });

        if (!method.liabilityShifted) {
            dropIn.clearSelectedPaymentMethod();
            return alert(t("3DSecureError"));
        }

        const status = isTrial ?
            await createSubscription(plan.id, method.nonce) :
            await setPaymentMethod(method.nonce)

        setSubStatus(status);
    }

    function renderAlert() {
        if (isFrozen)
            return <div className="alert alert-warning">
                {t("frozenAlert" , subStatus)}
            </div>

        if (willExpire)
            return <div className="alert alert-warning">
                {t("expirationAlert" , subStatus)}
            </div>

        if (isTrial)
            return <div className="alert alert-info">
                {t("trialAlert", subStatus)}
            </div>

        return null;
    }

    function renderPlan(plan, id) {
        const name = t(`planNames.${id}`, plan.name);

        const className = classNames("card", "plan", {
            "selected": id === selectedPlan
        });

        return <div className={className}
                    key={id}
                    onClick={() => setSelectedPlan(id)}
        >
            <h4 className="plan__heading">{name}</h4>
            <div className="plan__price">{t("eurosPerMonth", plan)}</div>
        </div>
    }

    function renderPlans() {
        return <div className="settingsPage__subscription__plans">
            <h3 className="settingsPage__section__heading">{t("plans")}</h3>
            <p>{t("plansDescription")}</p>
            <div className="settingsPage__subscription__plans__list">
                {_.map(plans, renderPlan)}
            </div>
        </div>;
    }

    function renderStatus() {
        return <div className="settingsPage__subscription__status">
            <h3 className="settingsPage__section__heading">{t("subscriptionStatus")}</h3>
            <div className="settingsPage__section__sub-heading">{t("subscriptionStatuses")[subStatus.name]}</div>
            {subStatus.isUpdatable && <>
                <Switch label={t("autoRenew")}
                              id="autoRenew"
                              checked={!willExpire}
                              onChange={async e => setSubStatus(await setAutoRenew(e.target.checked))}
                />
                <div>{t("nextBilling", subStatus)}</div>
            </>}
        </div>
    }

    return <>
        {renderAlert()}
        <div className="settingsPage__subscription">
            {isTrial ? renderPlans() : renderStatus()}
            <div className="settingsPage__subscription__buyer">
                <h3 className="settingsPage__section__heading">
                    {t("buyer")}
                    <HelpIcon tooltip={t("buyerDescription")}/>
                </h3>

                <Form className="settingsPage__subscription__buyer__form" name="buyerInfo" ref={buyerInfoFormRef}>
                    <FormInput path="givenName" label={t("firstName")}/>
                    <FormInput path="surname" label={t("lastName")}/>
                    <FormInput path="phoneNumber" label={t("phone")} type="tel"/>
                    <FormInput path="streetAddress" label={t("streetAddress")}/>
                    <FormInput path="extendedAddress" label={t("extendedAddress")}/>
                    <FormInput path="locality" label={t("locality")}/>
                    <FormInput path="region" label={t("region")}/>
                    <FormInput path="postalCode" label={t("postalCode")}/>
                    <FormDropdownList path="countryCodeAlpha2"
                                      label={t("countryCode")}
                                      options={t("countries")}
                    />
                </Form>
            </div>

            <div className="settingsPage__subscription__payment">
                <h3 className="settingsPage__section__heading">{t("payment")}</h3>
                <div ref={dropInContainerRef} />
                <button className="btn btn-outline-primary mt-2"
                        onClick={handleRequestClick}
                        disabled={!allowRequest}
                >{t(isSelect ? "select" : "add")}</button>
            </div>
        </div>
    </>
}

export default SubscriptionSetting;
