import {configureStore} from "@reduxjs/toolkit";
import {createTable} from "react-select-table";

export const locationsNs = "locations";
export const vetsNs = "vets";

const settingsStore = configureStore({
    reducer: {
        locations: createTable(locationsNs, {
            statePath: "locations"
        }),
        vets: createTable(vetsNs, {
            statePath: "vets"
        })
    }
})
export default settingsStore
