import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import {clientDate} from "pd-shared/formatUtils";

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        lng: "el",
        debug: true,
        ns: ["common"],
        defaultNS: "common",
        fallbackNS: "common",
        returnObjects: true,
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        interpolation: {
            escapeValue: false,
            format: function(value, format) {
                switch(format) {
                    case "date":
                        return clientDate(value);
                    default:
                        return value;
                }
            }
        },
        react: {
            transKeepBasicHtmlNodesFor: ["h1", "h2", "h3", "h4", "h5", "br", "i", "b", "code", "wbr"]
        }
    });
