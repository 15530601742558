import React from "react";
import {Trans, useTranslation} from "react-i18next";

function WelcomeSetting() {
    const {t} = useTranslation("settings")

    return <>
        <h2 className="settingsPage__heading">{t("welcome")}</h2>
        <Trans t={t} i18nKey="welcomeText">
            Download the app <a href={process.env.REACT_APP_WEB_URL + '/downloads'}>here</a>
        </Trans>
    </>
}

export default WelcomeSetting
