import http from "./httpService";

const endpoint = "/locations";

export const getLocations = () =>
    http.get(endpoint);

export const createLocation = location =>
    http.post(endpoint, location)

export const editLocation = (id, location) =>
    http.put(`${endpoint}/${id}`, location);

export const deleteLocation = id =>
    http.delete(`${endpoint}/${id}`);