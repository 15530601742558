import http, {withAuthTokenConfig} from "./httpService";

const endpoint = "/users";

export const register = body =>
    http.post(endpoint, body);

export const authenticate = body =>
    http.post(`${endpoint}/auth`, body);

export const getEmail = authToken =>
    http.get(`${endpoint}/email`, withAuthTokenConfig(authToken));

export const changeEmail = body =>
    http.put(`${endpoint}/email`, body);

export const changePassword = body =>
    http.put(`${endpoint}/password`, body);

export const forgotPassword = body =>
    http.post(`${endpoint}/forgot`, body);

export const resetPassword = body =>
    http.post(`${endpoint}/reset`, body);
