import "./NotFound.scss";
import React from 'react';
import {useTranslation} from "react-i18next";
import Navbar from "../Navbar/Navbar";

function NotFound() {
    const {t} = useTranslation();

    return <div id="notFoundPage">
        <Navbar/>
        <div className="container" id="message">
            <div id="statusCode">404</div>
            {t("pageNotFound")}<br/>
            <a href={process.env.REACT_APP_WEB_URL}>{t("returnToHome")}</a>
        </div>
    </div>
}

export default NotFound;
