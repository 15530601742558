import React, {useCallback, useEffect, useMemo} from 'react';
import {useSearchParams, useNavigate} from 'react-router-dom'
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {setAuthToken, getAuthToken, getAuthEmail} from "../../services/authService";
import {showServerError} from "../../utils/errorUtils";
import {Link} from "react-router-dom";
import {authenticate, getEmail} from "../../services/userService";
import FormPage from "../FormPage/FormPage";
import Form from "../Form/Form";
import {FormButton, FormInput} from "pd-shared";

function Login() {
    const defaultSearchParams = useMemo(() =>
        ({ from: "/settings", token: getAuthToken() }), []);

    const {t} = useTranslation("login");
    const [searchParams] = useSearchParams(defaultSearchParams);
    const navigate = useNavigate()

    const schema = useMemo(() => yup.object({
        email: yup.string().email().required().default(getAuthEmail()),
        password: yup.string().min(5).required()
    }), []);

    const login = useCallback((token, email) => {
        setAuthToken(token, email);
        navigate(searchParams.get('from'));
    }, [navigate, searchParams]);

    useEffect(() => {
        const token = searchParams.get('token')
        if (!token || searchParams.has('reason')) return;

        getEmail(token).then(email => {
            login(token, email)
        });
    }, [searchParams, login])

    const handleSubmit = async creds => {
        try {
            const token = await authenticate(creds);
            login(token, creds.email);
        } catch (err) {
            showServerError(err);
        }
    }

    return <FormPage alert={searchParams.get('reason')}>
        <Form className="formPage__form" name="login" schema={schema} onSubmit={handleSubmit}>
            <h1 className="formPage__heading">{t("login")}</h1>

            <FormInput path="email" label={t("email")} type="email"/>
            <FormInput path="password" label={t("password")} type="password"/>
            <div className="form-group">
                <FormButton className="btn btn-outline-primary w-100">{t("login")}</FormButton>
            </div>
            <Link className="btn btn-link" to="/forgot">{t("alzheimer")}</Link>
            <Link className="btn btn-link" to="/register">{t("noAccount")}</Link>
        </Form>
    </FormPage>
}

export default Login;
