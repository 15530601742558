import {useMemo} from "react";
import $ from 'jquery'

export default function useCollapse(id) {
    return useMemo(() => {
        const selector = "#" + id
        return {
            selector,
            show: () => $(selector).collapse('show'),
            hide: () => $(selector).collapse('hide'),
            toggleProps: {
                "data-toggle": "collapse",
                "data-target": selector
            }
        }
    }, [id])
}
