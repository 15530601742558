import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {changeEmail} from "../../services/userService";
import {showServerError} from "../../utils/errorUtils";
import Form from "../Form/Form";
import {FormButton, FormInput} from "pd-shared";

const schema = yup.object({
    newEmail: yup.string().email().required(),
    password: yup.string().min(5).required()
});

function EmailSetting() {
    const [success, setSuccess] = useState(false);
    const {t} = useTranslation("settings");

    const handleSubmit = async values => {
        try {
            await changeEmail(values);
            setSuccess(true);
        } catch (err) {
            showServerError(err);
        }
    }

    return <div>
        {success && <div className="alert alert-success">{t("emailSent")}</div>}
        <Form className="settingsPage__form" name="email" schema={schema} onSubmit={handleSubmit}>
            <FormInput path="newEmail" label={t("newEmail")} type="email" autoFocus/>
            <FormInput path="password" label={t("currentPassword")} type="password"/>
            <FormButton className="btn btn-outline-primary">{t("apply")}</FormButton>
        </Form>
    </div>
}

export default EmailSetting;
