import {createSearchParams} from "react-router-dom";

const storageKeys = {
    AuthToken: "authToken",
    Email: "email"
};

export function getAuthToken() {
    return localStorage.getItem(storageKeys.AuthToken) ?? "";
}

export function getAuthEmail() {
    return localStorage.getItem(storageKeys.Email) ?? "";
}

export function setAuthToken(token, email) {
    localStorage.setItem(storageKeys.AuthToken, token);
    localStorage.setItem(storageKeys.Email, email);
}

export function deleteAuthToken() {
    localStorage.removeItem(storageKeys.AuthToken);
    localStorage.removeItem(storageKeys.Email);
}

export function reAuthenticate(message) {
    const { location } = window
    const loginSearch = createSearchParams({
        reason: message,
        from: location.pathname
    });
    location.replace(`/login?${loginSearch}`);
}
