import React from 'react';
import _ from 'lodash';
import classNames from "classnames";
import PropTypes from 'prop-types';

ListGroup.propTypes = {
    items: PropTypes.objectOf(PropTypes.string),
    selected: PropTypes.string,
    onChange: PropTypes.func
};

function ListGroup({items, selected, onChange, className}) {
    return <ul className={classNames("list-group list-group-flush", className)}>
        {_.map(items, (item, value) => {
            const itemClass = classNames(
                "list-group-item", "clickable",
                { "selected": value === selected }
            );

            return <li className={itemClass}
                       key={value}
                       onClick={() => onChange(value)}
            >{item}</li>;
        })}
    </ul>
}

export default ListGroup;
