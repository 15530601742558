import "./Settings.scss";
import _ from 'lodash';
import React from 'react';
import {useTranslation} from "react-i18next";
import ListGroup from "./ListGroup";
import {useNavigate, useMatch, Outlet} from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import withStore from "../../hoc/withStore";
import settingsStore from "../../stores/settingsStore";
import {deleteAuthToken, getAuthEmail} from "../../services/authService";

function Settings() {
    const navigate = useNavigate()

    const {t} = useTranslation("settings");

    const selectedKey = useMatch("/settings/:id")?.params.id;

    if (selectedKey === "logout") {
        deleteAuthToken()
        window.location.reload();
    }

    const settingsList = t("settingsList");

    return <div className="page settingsPage">
        <Navbar>
            <div className="settingsPage__email">{getAuthEmail()}</div>
        </Navbar>
        <div className="page__container container">
            <div className="settingsPage__row row h-100 align-content-start align-content-md-stretch">
                <div className="settingsPage__selector px-0 py-3 col-md-4">
                    <h1 className="settingsPage__heading px-3">{t("settings")}</h1>
                    <ListGroup className="settingsPage__list"
                               items={_.mapValues(settingsList, s => s.title)}
                               selected={selectedKey}
                               onChange={id => navigate(id)}
                    />
                </div>
                <div className="settingsPage__content py-3 col-md-8">
                    {selectedKey in settingsList && <>
                        <h2 className="settingsPage__heading">
                            {t(`settingsList.${selectedKey}.title`)}
                        </h2>
                        <p>
                            {t(`settingsList.${selectedKey}.description`, { defaultValue: '' })}
                        </p>
                    </>}

                    <Outlet />
                </div>
            </div>
        </div>
    </div>
}

export default withStore(Settings, settingsStore);
