import http from "./httpService";

const endpoint = "/subscription"

export const getPlans = () =>
    http.get(`${endpoint}/plans`);

export const getBraintreeToken = () =>
    http.get(`${endpoint}/token`);

export const createSubscription = (plan, nonce) =>
    http.post(endpoint, {plan, nonce});

export const getSubscriptionStatus = () =>
    http.get(`${endpoint}/status`);

export const setPaymentMethod = nonce =>
    http.put(`${endpoint}/method`, {nonce});

export const setAutoRenew = enabled =>
    http.put(`${endpoint}/renew`, {enabled})