import {Provider} from "react-redux";
import React from "react";

export default function withStore(Component, store) {
    return function WithStore(props) {
        return <Provider store={store}>
            <Component {...props} />
        </Provider>
    }
}
